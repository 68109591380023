@tailwind base;
@tailwind components;
@tailwind utilities;


html, body, #__next, .font-sans {
    height: 100%;
    
}

.replay.replay-aspect-ratio-fix {
    height: 100%;
}
    
body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    background-color: rgb(38, 38, 38);
}

.category-wrapper {
    height: calc(100% - 72px);
}